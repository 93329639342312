import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Axios } from '../../api/instances';
import { toast } from 'react-toastify';
import axios from 'axios';



const initialState = {
  isItemsLoading: false,
  error: null,
  items: [],
  inventories:[]
  
};



const getInventory = createAsyncThunk('item', async () => {
   try {
    let accessToken = localStorage.getItem('accessToken')
      ? JSON.parse(localStorage.getItem('accessToken'))
      : null;
      let zohoAccessToken = localStorage.getItem('zohoAccessToken')
        ? JSON.parse(localStorage.getItem('zohoAccessToken'))
        : null;
    const res = await Axios.post(
        "/zoho/getinventory",
       {"accessToken":zohoAccessToken},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          
        }
      );
      
      console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")
      console.log(res)
      

    return res.data.data;
  } catch (error) {
    throw error.response.data || error.message;
  }
});


/*const getAllInventory = createAsyncThunk('items', async () => {
  try {
   let accessToken = localStorage.getItem('accessToken')
     ? JSON.parse(localStorage.getItem('accessToken'))
     : null;
     
   const res = await Axios.get(
       "/getallinventory",
      
       {
         headers: {
           Authorization: `Bearer ${accessToken}`,
         },
         
       }
     );
  
     console.log("ooooooooooooooooooooooooooooooooo")
     console.log(res)
     

   return res.data.data;
 } catch (error) {
   throw error.response.data || error.message;
 }
});*/

const postAllInventory = createAsyncThunk('items/upload', async (file,{ rejectWithValue }) => {
  const formData = new FormData();
  formData.append('file', file);
   console.log(formData,'dghdghhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh')
  try {
    const response = await axios.post('http://127.0.0.1:4000/v1/uploadInventory',formData,{

     headers:{
    
        'Content-Type': 'multipart/form-data',

     }
    });
    console.log('vvvvvvvvvvvvvvvvvvvvvvvvvvmmmmmmmmmmm', response.data.data);
    return response.data.data;
  } catch (error) {
    console.error('Upload failed:', error.response ? error.response.data : error.message);
    return rejectWithValue(error.response.data || error.message);
  }
});

const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getInventory.pending, (state, action) => {
        state.isItemsLoading = true;
      })
      .addCase(getInventory.fulfilled, (state, action) => {
        console.log(typeof action.payload,"problem")
        state.items = action.payload;
        state.isItemsLoading = false;
      })
      .addCase(getInventory.rejected, (state, action) => {
        toast.warning('An error occured ', {
          autoClose: 2000,
        });
        state.isItemsLoading = false;
      })
      .addCase(postAllInventory.pending, (state, action) => {
        state.isItemsLoading = true;
      })
      .addCase(postAllInventory.fulfilled, (state, action) => {
        console.log(typeof action.payload,"problems")
        state.isItemsLoading = false;
      })
      .addCase(postAllInventory.rejected, (state, action) => {
        toast.warning('An error occured ', {
          autoClose: 2000,
        });
        state.isItemsLoading = false;
      })
      
  },
});

export {
 getInventory,
 postAllInventory,
};

export default inventorySlice.reducer;
