import React, { useRef } from 'react';
import Sidebar from '../components/Sidebar'
import video1 from "../assets/202408200503.mp4"

const Video = () => {
    const videoRef = useRef(null);

  const handlePause = () => {
    console.log('Video is paused');
    // You can add more logic here if needed
  };

  const handlePlay = () => {
    console.log('Video is playing');
    // You can add more logic here if needed
  };
     
  return (
   <>
     <Sidebar />
    <div className='relative md:ml-64 bg-blueGray-100'>
  <div className=' justify-center mx-40 space-y-16'>
  <p className=' font-DM Mono text-xl font-bold'>FORECAST APPLICATION</p>
   <video
      ref={videoRef}
      src={video1}
      autoPlay
      onPause={handlePause}
      onPlay={handlePlay}
      controls
      width={800}
    />
  </div>
                
    
    </div>
   </>
  )
}

export default Video