import React,{useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFile,
  faUserCircle,
  faCog,
  faSignOut,
  faBars,
  faTimes,
  faRotate,
  faMoneyBillTrendUp,
  faCreditCard,
  faBook,
  faFileInvoice,
   faLaptop,
   faFileExcel,
   faVideo
} from '@fortawesome/free-solid-svg-icons';
import DurationDropdown from './DurationDropdown.js';
import { NavLink } from 'react-router-dom';
import { logout } from '../redux/slices/auth';
import logo from '../assets/logo.png';
import Tour from 'reactour';
const Sidebar = () => {
  let activeStyle = {
    color:'black',
    fontSize: '17px',
    borderRadius:'0.5rem',
    backgroundColor:'#feecf0',
    width:'100%',
    border:'5px',
    fontStyle: 'bold',
  };
  const dispatch = useDispatch();
  const [collapseShow, setCollapseShow] = React.useState('hidden');
  const [isTourOpen, setIsTourOpen ] = useState(false)
  let user = useSelector((state) => state.auth.user);

  const tour = [
    {
      selector:'first step',
      content:'The dashboard displays the forecast periods, with graphs distinguishing between dollar and naira, as well as detailed inflow and outflow information.',
      position: 'bottom',
      action: () => console.log('Step 1 action')
    },
    
    {
      selector:'second step',
      content:'Rates for viewing exchange rate in the platform',
      position: 'top',
      action: () => console.log('Step 2 action')
    },
    {
      selector:'third step',
      content:'Opening Balance allows you to view and download all available balances in Excel format.',
      position: 'top',
      action: () => console.log('Step 3 action')
    },
    {
      selector:'forth step',
      content:'Accrued expenses is where all the expenses gotten from the company are stored. Click on accured button , click on the add ',
      position: 'top',
      action: () => console.log('Step 4 action')
    },
    {
      selector:'fifth step',
      content:'Prepaid expenses is the same as Accrued balances',
      position: 'bottom',
      action: () => console.log('Step 5 action')
    },
    {
      selector:'sixth step',
      content:'Inventories is for viewing all available stocks on zoho inventory',
      position: 'top',
      action: () => console.log('Step 6 action')
    },
    {
      selector:'seventh step',
      content:'Settings for updating the exchange rate and changing the password',
      position: 'top',
      action: () => console.log('Step 7 action')
    },
    {
      selector:'eight step',
      content:'Logout from the forecast  application',
      position: 'bottom',
      action: () => console.log('Step 8 action')
    }
  ]
  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6" style={{backgroundColor:"#a64f5f"}}>
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
        
          <button
            className="cursor-pointer justify-center text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow('bg-white m-2 py-3 px-6')}
          >
            <FontAwesomeIcon
              icon={faBars}
              style={{ fontSize: 15, color: 'red', paddingRight: 5 }}
            />
          </button>
          {/* Brand Logo */}
          <div className="top-6 text-sm w-full  py-3">
            <a href="/">
              <img
                className="img"
                alt="manifold logo"
                src={logo}
                width="120px"
                height="40px"
              />
            </a>
          </div>
          {/* Duration */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              <DurationDropdown />
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              'md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded ' +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block  pb-4 mb-4 border-b border-solid border-blueGray-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <a className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm  font-bold p-4 px-0">
                    Manifold Computers
                  </a>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow('hidden')}
                  >
                    <FontAwesomeIcon
                      icon={faTimes}
                      style={{ fontSize: 20, color: 'red', paddingRight: 5 }}
                    />
                  </button>
                </div>
              </div>
            </div>
           
          
            {/* Navigation */}
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <NavLink
                  className=" text-white text-lg  py-3 font-medium block"
                  to="/"
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                >
                  <FontAwesomeIcon
                    icon={faFile}
                    style={{
                      fontSize: 13,
                      color:'text-white',
                      paddingRight: 20,
                      left:12,
                      position:'relative',
                    
                      
                    }}
                  />
                  Dashboard
                </NavLink>
              </li>

             
              
                <li className="items-center">
                  <NavLink
                    className=" text-white  hover:text-black text-lg   py-3 font-medium block"
                    to="/user"
                    style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                    }
                  >
                    <FontAwesomeIcon
                      icon={faUserCircle}
                      style={{
                        fontSize: 13,
                        color: 'text-blueGray-700',
                        paddingRight: 20,
                       left:12,
                      position:'relative'

                      }}
                    />
                    User Management
                  </NavLink>
                </li>

               
              <li className="items-center">
                  <NavLink
                    className=" text-white  hover:text-black text-lg   py-3 font-medium block"
                    to="/exchange-rates"
                    style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                    }
                  >
                    <FontAwesomeIcon
                       icon={faRotate}
                      style={{
                        fontSize: 13,
                        color: 'text-blueGray-700',
                        paddingRight: 20,
                       left:12,
                      position:'relative'

                      }}
                    />
                     Rates
                  </NavLink>
                </li>

          
                
              <li className="items-center">
                  <NavLink
                    className=" text-white  hover:text-black text-lg   py-3 font-medium block"
                    to="/opening-balance"
                    style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                    }
                  >
                    <FontAwesomeIcon
                      icon={faMoneyBillTrendUp}
                      style={{
                        fontSize: 13,
                        color: 'text-blueGray-700',
                        paddingRight: 20,
                       left:12,
                      position:'relative'

                      }}
                    />
                     Opening Balance
                  </NavLink>
                </li>

               
              
                <li className="items-center">
                  <NavLink
                    className=" text-white  hover:text-black text-lg   py-3 font-medium block"
                    to="/expense"
                    style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                    }
                  >
                    <FontAwesomeIcon
                      icon={faFileInvoice}
                      style={{
                        fontSize: 13,
                        color: 'text-blueGray-700',
                        paddingRight: 20,
                       left:12,
                      position:'relative'

                      }}
                    />
                   Accrued Expenses
                  </NavLink>
                </li>
              
                <li className="items-center">
                  <NavLink
                    className=" text-white  hover:text-black text-lg   py-3 font-medium block"
                    to="/prepaid"
                    style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                    }
                  >
                    <FontAwesomeIcon
                      icon={faLaptop}
                      style={{
                        fontSize: 13,
                        color: 'text-blueGray-700',
                        paddingRight: 20,
                       left:12,
                      position:'relative'

                      }}
                    />
                  Prepaid Expenses
                  </NavLink>
                </li>
              
                <li className="items-center">
                  <NavLink
                    className=" text-white  hover:text-black text-lg   py-3 font-medium block"
                    to="/inventories"
                    style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                    }
                  >
                    <FontAwesomeIcon
                      icon={faLaptop}
                      style={{
                        fontSize: 13,
                        color: 'text-blueGray-700',
                        paddingRight: 20,
                       left:12,
                      position:'relative'

                      }}
                    />
                    Inventories
                  </NavLink>
                </li>

               
                


              {user.role && (
                <li className="items-center">
                  <NavLink
                    className=" text-white hover:text-black text-sm   py-3 font-bold block"
                    to="/overdraft"
                    style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                    }
                  >
                    <FontAwesomeIcon
                      icon={faCreditCard}
                      style={{
                        fontSize: 13,
                        color: ' text-white',
                        paddingRight: 20,
                       left:12,
                      position:'relative'
                      }}
                    />
                    Overdraft
                  </NavLink>
                </li>
              )}

              

              <li className="items-center">
                <NavLink
                  className=" text-white hover:text-black text-lg   py-3 font-medium block"
                  to="/setting"
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                >
                  <FontAwesomeIcon
                    icon={faCog}
                    style={{
                      fontSize: 13,
                      color: ' text-white',
                      paddingRight: 20,
                      left:12,
                      position:'relative'
                    }}
                  />
                  Setting
                </NavLink>
              </li>
              <li className="items-center">
                <NavLink
                  className=" text-white hover:text-black text-lg   py-3 font-medium block"
                  to="/login"
                  onClick={() => dispatch(logout())}
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                >
                  <FontAwesomeIcon
                    icon={faSignOut}
                    style={{
                      fontSize: 13,
                      color: ' text-white',
                      paddingRight: 20,
                      left:12,
                      position:'relative'
                    }}
                  />
                  Logout
                </NavLink>
              </li>
              <li className="items-center">
                <NavLink
                  className=" text-white hover:text-black text-lg   py-3 font-medium block"
                  to="/video"
                  onClick={() => dispatch(logout())}
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                >
                  <FontAwesomeIcon
                    icon={faVideo}
                    style={{
                      fontSize: 13,
                      color: ' text-white',
                      paddingRight: 20,
                      left:12,
                      position:'relative'
                    }}
                  />
                 Tutorial Video
                </NavLink>
              </li>
            </ul>
            <div>
           <button onClick={()=>setIsTourOpen(true)} type='button' className=' text-white bg-black rounded px-5'>tutorial</button>
      <Tour 
      steps={tour}
       isOpen={isTourOpen}
        onRequestClose={()=>setIsTourOpen(false)}
        rounded={5}
        maskSpace={10}
        showNavigation={true}
        showNumber={true}
        disableKeyboardNavigation={false}
        className='relative z-50 bg-black  '
       />
           </div>
          </div>
        </div>
      </nav>
    </>
  );
};
export default Sidebar;
