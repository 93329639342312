import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { InfinitySpin } from 'react-loader-spinner';
import logo from '../assets/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faHands } from '@fortawesome/free-solid-svg-icons';
import { login } from '../redux/slices/auth';
import { NavLink, Navigate, useSearchParams } from 'react-router-dom';
import pixel from "../assets/first-image.jpeg"


const Login = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  
  const authLoading = useSelector((state) => state.auth.isAuthLoading);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isZohoAuthenticated = useSelector(
    (state) => state.auth.isZohoAuthenticated
  );
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams(props);

  const changeEmailHandler = (e) => setEmail(e.target.value);
  const changePasswordHandler = (e) => setPassword(e.target.value);

  const loginHandler = (e) => {
    e.preventDefault();
    console.log('...>clicked on the login button')
    dispatch(login({ email, password }));
    
  };
   
  const togglePasswordVisibility = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  
  return (
    <div className="main">
      <div className=' grid grid-cols-2 '>
          <div className=''>
          <img src={pixel} className=" bg-cover h-screen w-full " />
          </div>
          <div className=' h-screen ' style={{backgroundColor:"#f8f9f9"}}>
            <div className='relative top-12 m-16'>
            <div className='flex space-x-2'>
            <FontAwesomeIcon icon={faHands} className='' style={{fontSize:35,color:'#c49770'}}/>
            <h1 className='font-bold text-3xl '> Welcome back</h1>
            </div>
            <p className=' text-lg' style={{color:'#7c7e8c'}}>Please input your login details.</p>
            {isAuthenticated &&
              !isZohoAuthenticated &&
              !authLoading &&
              window.location.replace(
                `https://accounts.zoho.com/oauth/v2/auth?scope=ZohoBooks.settings.READ,ZohoBooks.invoices.READ,ZohoBooks.salesorders.READ,ZohoBooks.purchaseorders.READ,ZohoBooks.bills.READ,ZohoBooks.vendorpayments.READ,ZohoBooks.customerpayments.READ,ZohoBooks.banking.READ&client_id=${process.env.REACT_APP_ZOHO_CLIENT_ID}&state=testing&response_type=code&redirect_uri=${process.env.REACT_APP_BASE_URL}&access_type=offline&prompt=Consent`
              )}
            {isAuthenticated && isZohoAuthenticated && (
              <Navigate to="/" replace={true} />
            )}
            <form className=' relative top-10 space-y-4'  onSubmit={loginHandler}>
               <div  className='' >
               <input
                  type="email"
                  id="email"
                  placeholder="Enter Email Address"
                  className="w-full px-4 py-3 rounded-lg bg-white mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                  onChange={changeEmailHandler}
                  required
                />
               </div>
               <div>
               <input
                  type="password"
                  id="Password"
                  placeholder="Enter Password"
                  className="w-full px-4 py-3 rounded-lg bg-white mt-2 border focus:border-grey-200 focus:bg-white focus:outline-none"
                  onChange={changePasswordHandler}
                  required
                />
               </div>
               
              <div className='grid grid-cols-2'>
              <div className='flex space-x-2 mt-2'>
               <input type='checkbox' className='' size={20} style={{fontSize:12}}/>
               <p className=' text-sm font-semibold '>Keep me logged in</p>
               </div>
               <div className=" text-right mt-2">
                <NavLink
                  className="text-sm font-semibold text-red-700 hover:text-red-500 focus:text-red-500"
                  to="/forgot-password"
                  style={{color:'#f5426c'}}
                >
                  Forgot Password?
                </NavLink>
              </div>
              </div>
              <button
                type="submit"
                disabled={authLoading}
                className="w-full block bg-red-400 hover:bg-red-300 focus:bg-red-300 text-white font-semibold rounded-lg
                px-4 py-3 mt-6" 
                style={{backgroundColor:'#f5426c'}}
              >
               {authLoading ? 'loading...' : 'Login'}{' '}
              </button>
            </form>
            </div>
         </div>
        
      </div>
    </div>
  );
};

export default Login;
